import React, { useContext } from "react";

// Third party
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

/* Context */
import Context from "../../../../store/context";
import { DeleteContext } from "../../../../store/DeleteProvider";
import CircularLoading from "../../../../UI/CircularLoading/CircularLoading";

/* Custom Button */
import Button from "../../../../UI/Button/Button";

/* MUI */
import Switch from "@mui/material/Switch";

/* Icons */
import { FiEdit } from "react-icons/fi";
import { Delete } from "../../../../assets/Icons";
import { BsCheckLg } from "react-icons/bs";

// RTK query
import {
	useChangePackageStatusMutation,
	usePackageDeleteItemMutation,
} from "../../../../RTK/apiSlices/subscriptionsApi";

// components
import NewPagination from "../../../PaginationComp/NewPagination";
import DeleteOneModalComp from "../../../DeleteOneModal/DeleteOneModal";

//  styles
import styles from "./Plans.module.css";

const switchStyle = {
	width: "32px",
	padding: 0,
	height: "20px",
	borderRadius: "0.75rem",
	"& .MuiSwitch-thumb": {
		width: "12px",
		height: "12px",
	},
	"& .MuiSwitch-switchBase": {
		padding: "0",
		top: "4px",
		left: "4px",
	},
	"& .MuiSwitch-switchBase.Mui-checked": {
		left: "-4px",
	},
	"& .Mui-checked .MuiSwitch-thumb": {
		backgroundColor: "#FFFFFF",
	},
	"& .MuiSwitch-track": {
		height: "100%",
	},
	"&.MuiSwitch-root .Mui-checked+.MuiSwitch-track": {
		backgroundColor: "#3AE374",
		opacity: 1,
	},
};

// handle sorting plans based select value
export const PackagePlan = ({ item, color }) => {
	return (
		<div className='pr-4'>
			{item?.map((plan, index) => {
				return (
					plan?.selected && (
						<h2
							className={`font-normal my-4 text-lg flex justify-start items-start `}
							style={{ color: color }}
							key={index}>
							<BsCheckLg
								style={{
									color: "#3AE374",
									display: "inline-block",
									marginLeft: "0.1rem",
									fontSize: "1.30rem",
								}}
							/>
							<span> {plan?.name}</span>
						</h2>
					)
				);
			})}
		</div>
	);
};

const Plans = ({
	loading,
	pageNumber,
	currentPage,
	page_count,
	fetchedData,
	setPageNumber,
	setCurrentPage,

	// editPackageTemplate,
}) => {
	const navigate = useNavigate();
	const contextStore = useContext(Context);
	const DeleteStore = useContext(DeleteContext);
	const { setEndActionTitle } = contextStore;

	const { setItemId, setActionDelete, actionDelete } = DeleteStore;

	// change status of packages
	const [changePackageStatus] = useChangePackageStatusMutation();
	const changeStoreStatus = async (id) => {
		try {
			await changePackageStatus({ id })
				.unwrap()
				.then((data) => {
					if (data.success) {
						setEndActionTitle(data.message.ar);
					}
				});
		} catch (err) {
			console.error("Failed to delete the category", err);
		}
	};

	// delete package
	const [packageDeleteItem] = usePackageDeleteItemMutation();
	const handleDeleteSingleItem = async (id) => {
		try {
			await packageDeleteItem({ id })
				.unwrap()
				.then((data) => {
					if (!data?.success) {
						toast.error(data?.message?.ar, {
							theme: "light",
						});
					}
				});
		} catch (err) {
			console.error("Failed to delete the delete package", err);
		}
	};

	// Find the package with the highest yearly_price
	const highestPricedPackage = fetchedData
		?.filter((item) => item?.status === "نشط")
		?.reduce((max, item) => {
			// Calculate the price considering the discount
			const priceWithDiscount =
				item.discount > 0
					? item.yearly_price - item.discount
					: item.yearly_price;

			// Determine if the current item should be the new max
			return priceWithDiscount >
				(max
					? max.discount > 0
						? max.yearly_price - max.discount
						: max.yearly_price
					: 0)
				? item
				: max;
		}, null);

	return (
		<div>
			<div className='flex flex-col flex-wrap items-center gap-4 md:flex-row'>
				{loading ? (
					<div className='flex flex-col items-center justify-center w-full'>
						<CircularLoading />
					</div>
				) : fetchedData?.length > 0 ? (
					fetchedData?.map((p, index) => {
						const proPackage =
							p === highestPricedPackage ? styles.pro : styles.normal;
						return (
							<div
								key={index}
								className={`flex flex-col justify-between flex-1 md:min-w-[370px] md:max-w-[400px] w-full p-5 rounded-lg min-h-[690px] ${proPackage}`}>
								<div className='py-6 '>
									<h2 className='flex items-center justify-center gap-4 mb-6 text-2xl font-medium'>
										{p?.name}
									</h2>

									<p
										style={{ color: p === highestPricedPackage ? "#fff" : "" }}
										className='flex gap-1 justify-center items-baseline text-center'>
										{p?.discount > 0 ? (
											p?.yearly_price - p?.discount === 0 ? (
												<span className=' text-4xl font-semibold'>مجاناً</span>
											) : (
												<>
													<span className=' text-4xl font-semibold'>
														{p?.yearly_price - p?.discount}
													</span>
													ر.س
													<span className='line-through font-semibold text-primary-600'>
														{p?.yearly_price}
														ر.س
													</span>
												</>
											)
										) : (
											<span className=' text-4xl font-semibold'>
												{p?.yearly_price}
												<span className='text-lg font-normal'>ر.س</span>
											</span>
										)}
										<span className='text-primary-200 mr-1'>
											/{" "}
											{p?.periodtype === "year"
												? "سنوياََ"
												: p?.periodtype === "6months"
												? "6 أشهر"
												: p?.periodtype === "3months"
												? "3 أشهر"
												: p?.periodtype === "month"
												? "شهرياََ"
												: p?.periodtype === "2weeks"
												? "14 يوم"
												: ""}
										</span>
									</p>

									<PackagePlan
										color={{ color: highestPricedPackage ? "#fff" : "#000" }}
										item={p?.plans}
										highestPricedPackage={highestPricedPackage}
									/>
								</div>
								<div className='flex flex-col items-center justify-center w-full '>
									<div className='flex mb-6 gap-3 items-center justify-center w-full '>
										<Delete
											title='حذف'
											onClick={() => {
												setActionDelete(
													"سيتم حذف  الباقة وهذة الخطوة غير قابلة للرجوع"
												);
												setItemId(p?.id);
											}}
											style={{
												cursor: "pointer",
												color: "red",
												fontSize: "1rem",
											}}
										/>
										<Switch
											onChange={() => changeStoreStatus(p?.id)}
											sx={switchStyle}
											checked={p?.status === "نشط" ? true : false}
										/>
									</div>
									<Button
										svg={<FiEdit style={{ color: "#0099FB" }} />}
										type={p === highestPricedPackage ? "normal" : "outline"}
										className={`mb-2 w-full h-14  text-xl font-medium  ${
											p === highestPricedPackage
												? "bg-slate-50 text-[#0099FB]"
												: ""
										} `}
										style={{
											borderColor: p === highestPricedPackage ? "" : "#0099FB",
										}}
										textStyle={{ color: "#0099FB" }}
										onClick={() => {
											navigate(`edit-package/${p?.id}`);
										}}>
										تعديل خطط الباقة
									</Button>
									{/* <Button
										svg={
											<AiOutlineEye
												style={{
													color: `  ${
														p === highestPricedPackage ? "#fff" : "#67747B"
													} `,
												}}
											/>
										}
										className={"w-full h-14 text-xl font-medium "}
										type={"outline"}
										style={{
											border: ` 1px solid ${
												p === highestPricedPackage ? "#EFF9FF" : "#67747B"
											} `,
										}}
										textStyle={{
											color: p === highestPricedPackage ? "#EFF9FF" : "#67747B",
										}}
										onClick={() => {
											editPackageTemplate(p?.templates);
										}}>
										عرض قوالب الباقة
									</Button> */}
								</div>
							</div>
						);
					})
				) : (
					<h4 className='flex items-center justify-center gap-4 mb-6 text-lg font-medium text-red-500 w-full'>
						لا يوجد باقات
					</h4>
				)}

				{actionDelete && (
					<DeleteOneModalComp handleDeleteSingleItem={handleDeleteSingleItem} />
				)}
				{fetchedData?.length > 0 ? (
					<NewPagination
						pageNumber={pageNumber}
						setPageNumber={setPageNumber}
						currentPage={currentPage}
						setCurrentPage={setCurrentPage}
						page_count={page_count}
					/>
				) : null}
			</div>
		</div>
	);
};

export default Plans;
