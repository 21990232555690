import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../../API/axiosBaseQuery";

// Create API slice
export const selectorApi = createApi({
	reducerPath: "selectorApi",

	baseQuery: axiosBaseQuery({
		baseUrl: "https://backend.atlbha.com/api/",
	}),
	tagTypes: ["Selector"],

	endpoints: (builder) => ({
		// Get Shipping Cities 5 Data
		shippingCitiesData: builder.query({
			query: () => ({ url: `selector/shippingcities/1` }), // aramex cities

			transformResponse: (response) => response.data,
			providesTags: ["ShippingCities"],
		}),

		// Get Cities Data
		citiesData: builder.query({
			query: () => ({ url: `selector/cities` }),

			transformResponse: (response) => response.data,
			providesTags: ["Cities"],
		}),

		adminCitiesData: builder.query({
			query: () => ({ url: `Admin/selector/cities` }),

			transformResponse: (response) => response.data,
			providesTags: ["Cities"],
		}),

		// Get Countries Data
		countriesData: builder.query({
			query: () => ({ url: `selector/countries` }),

			transformResponse: (response) => response.data,
			providesTags: ["Countries"],
		}),

		// Get Countries Data
		adminCountriesData: builder.query({
			query: () => ({ url: `Admin/selector/countries` }),

			transformResponse: (response) => response.data,
			providesTags: ["Countries"],
		}),

		// Get Activities Data
		activitiesData: builder.query({
			query: () => ({ url: `selector/activities` }),

			transformResponse: (response) => response.data,
			providesTags: ["Activities"],
		}),

		// Get Selector Etlobah Category
		selectorEtlobahCategory: builder.query({
			query: () => ({
				url: `selector/etlobahCategory`,
			}),

			transformResponse: (response) => response.data,
			providesTags: ["SelectorEtlobahCategory"],
		}),

		// Get Selector Etlobah Category Admin
		adminSelectorEtlobahCategory: builder.query({
			query: () => ({
				url: `Admin/selector/etlobahCategory`,
			}),

			transformResponse: (response) => response.data,
			providesTags: ["SelectorEtlobahCategory"],
		}),

		// Get Sub Categories Data
		subCategoriesData: builder.query({
			query: ({ queryParams }) => ({
				url: `Admin/selector/subcategories?${queryParams}`,
			}),
			transformResponse: (response) => response.data,
			providesTags: ["SubCategories"],
		}),

		// Get plans Data
		adminSelectorPlans: builder.query({
			query: () => ({ url: `Admin/selector/plans` }),

			transformResponse: (response) => response.data,
			providesTags: ["Plans"],
		}),

		// Get templates Data
		adminSelectorTemplates: builder.query({
			query: () => ({ url: `Admin/selector/templates` }),

			transformResponse: (response) => response.data,
			providesTags: ["Templates"],
		}),

		// Get Page Categories Data
		adminPageCategories: builder.query({
			query: () => ({ url: `Admin/selector/page-categories` }),

			transformResponse: (response) => response.data,
			providesTags: ["PageCategories"],
		}),

		// Get Post Categories Data
		adminPostCategories: builder.query({
			query: () => ({ url: `Admin/selector/post-categories` }),

			transformResponse: (response) => response.data,
			providesTags: ["PostCategories"],
		}),

		// Get Post Categories Data
		adminRoles: builder.query({
			query: () => ({ url: `Admin/selector/roles` }),

			transformResponse: (response) => response.data,
			providesTags: ["Roles"],
		}),

		// select packages
		selectPackages: builder.query({
			query: () => ({ url: `Admin/selector/packages` }),

			transformResponse: (response) => response.data?.packages,
		}),

		// select period types of packages
		selectPackagesPeriodTypes: builder.query({
			query: () => ({ url: `Admin/selector/periodTypes` }),

			transformResponse: (response) => response.data?.periodTypes,
		}),

		// select packages courses for trips
		selectPackagesCourses: builder.query({
			query: () => ({ url: `Admin/selector/courses` }),

			transformResponse: (response) => response.data.courses,
		}),

		// select packages trips for trips
		selectPackagesTrips: builder.query({
			query: () => ({ url: `Admin/selector/trips` }),

			transformResponse: (response) => response.data.trips,
		}),

		// filter by coupon
		selectCoupons: builder.query({
			query: () => ({
				url: `Admin/coupons?all=1`,
			}),

			transformResponse: (response) => response.data.coupons,
			providesTags: ["WebsiteOrders"],
		}),
	}),
});

// Export endpoints and hooks
export const {
	useShippingCitiesDataQuery,
	useCountriesDataQuery,
	useAdminCountriesDataQuery,
	useCitiesDataQuery,
	useAdminCitiesDataQuery,
	useActivitiesDataQuery,
	useSubCategoriesDataQuery,
	useSelectorEtlobahCategoryQuery,
	useAdminSelectorEtlobahCategoryQuery,
	useAdminSelectorPlansQuery,
	useAdminSelectorTemplatesQuery,
	useAdminPageCategoriesQuery,
	useAdminPostCategoriesQuery,
	useAdminRolesQuery,
	useSelectPackagesQuery,
	useSelectPackagesPeriodTypesQuery,
	useSelectPackagesCoursesQuery,
	useSelectPackagesTripsQuery,
	useSelectCouponsQuery,
} = selectorApi;
