import React, { useState, useContext, useEffect } from "react";
import styles from "./EditService.module.css";
/* Third party */
import { Helmet } from "react-helmet";
import ImageUploading from "react-images-uploading";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
/* Custom Hook */

/* Contexts */
import Context from "../../../store/context";
import { LoadingContext } from "../../../store/LoadingProvider";
/* Components */
import CircularLoading from "../../../UI/CircularLoading/CircularLoading";
/* Custom Button */
import Button from "../../../UI/Button/Button";
/* MUI */
import Box from "@mui/material/Box";
/* Icons */
import CloseIcon from "@mui/icons-material/Close";
import { BsPlayCircle } from "react-icons/bs";
import { AddIcon } from "../../../assets/Icons";
import {
	useGetServiceDataQuery,
	useUpdateServiceMutation,
} from "../../../RTK/apiSlices/servicesApi";
import { TextEditorContext } from "../../../store/TextEditorProvider";
import { TextEditor } from "../../TextEditor";

// back_drop
const BackDrop = ({ onClick }) => {
	return (
		<div
			onClick={onClick}
			className={`fixed back_drop bottom-0 left-0  w-full bg-slate-900  z-10 ${styles.back_drop}`}
			style={{ height: "calc(100% - 4rem)" }}></div>
	);
};

// some styles
const formTitleClasses =
	"md:w-[315px] w-full font-semibold md:text-lg text-[16px] md:mb-0 mb-2";
const formInputClasses =
	"md:w-[555px] w-full md:h-14 h-[45px] p-4 outline-0 rounded-md";
const formInputStyle = {
	border: "1px solid #A7A7A7",
	backgroundColor: "#FFFFFF",
};

const EditService = () => {
	const { id } = useParams();
	const navigate = useNavigate();
	const LoadingStore = useContext(LoadingContext);
	const { setLoadingTitle } = LoadingStore;

	const editorContent = useContext(TextEditorContext);
	const { editorValue, setEditorValue } = editorContent;

	const { data: fetchedData, isLoading: loading } = useGetServiceDataQuery({
		showServiceId: id,
	});
	const [url, setUrl] = useState("");
	const [images, setImages] = useState([]);
	const [imagesPerview, setImagesPerview] = useState([]);
	const maxFileSize = 1 * 1024 * 1024; // 1 MB;
	const onChange = (imageList, addUpdateIndex) => {
		const isSizeValid = imageList.every(
			(image) => image.file.size <= maxFileSize
		);
		const isVideo =
			imageList?.[imageList.length - 1]?.file?.type.startsWith("video/");
		if (!isSizeValid) {
			setDataError({
				...DataError,
				file: isVideo
					? "حجم الفيديو يجب أن لا يزيد عن 1 ميجابايت."
					: "حجم الصورة يجب أن لا يزيد عن 1 ميجابايت.",
			});
			toast.warning(
				isVideo
					? "حجم الفيديو يجب أن لا يزيد عن 1 ميجابايت."
					: "حجم الصورة يجب أن لا يزيد عن 1 ميجابايت.",
				{ theme: "light" }
			);
		} else {
			setImages(imageList);
			setDataError({ ...DataError, file: null });
		}
	};

	const [product, setProduct] = useState({
		name: "",
		price: "",
	});

	const {
		register,
		handleSubmit,
		reset,
		control,
		formState: { errors },
	} = useForm({
		mode: "onBlur",
		defaultValues: {
			name: "",

			price: "",
		},
	});

	useEffect(() => {
		if (fetchedData?.service) {
			setProduct({
				...product,
				name: fetchedData?.service?.name,
				price: fetchedData?.service?.price,
			});
			// setImagesPerview(
			// 	fetchedData?.service?.file ===
			// 		"https://backend.atlbha.com/storage/images/service/"
			// 		? "https://placehold.co/120x120"
			// 		: fetchedData?.service?.file
			// );
			setEditorValue(fetchedData?.service?.description);
		} else {
			setEditorValue("");
		}
	}, [fetchedData?.service]);

	// To Handle Errors
	useEffect(() => {
		reset(product);
	}, [product, reset]);

	const handleClosePage = () => {
		navigate("/services");
		setEditorValue("");
	};

	const [updateService] = useUpdateServiceMutation();
	const updateServiceHandeler = async (formData) => {
		try {
			const res = await updateService({
				serviceId: id,
				body: formData,
			});
			if (res?.data?.success === true && res?.data?.data?.status === 200) {
				setLoadingTitle(null);

				handleClosePage();
			} else {
				setLoadingTitle(null);
				setDataError({
					name: res?.data?.message?.en?.name?.[0],
					description: res?.data?.message?.en?.description?.[0],
					price: res?.data?.message?.en?.price?.[0],
					file: res?.data?.message?.en?.file?.[0],
				});
				toast.error(res?.data?.message?.en?.name?.[0], { theme: "light" });
				toast.error(res?.data?.message?.en?.description?.[0], {
					theme: "light",
				});
				toast.error(res?.data?.message?.en?.price?.[0], { theme: "light" });
				// toast.error(res?.data?.message?.en?.file?.[0], { theme: "light" });
			}
		} catch (error) {
			console.error(error);
		}
	};

	const handleAddService = (data) => {
		resetData();
		setLoadingTitle("جاري تعديل الخدمة");
		const formData = new FormData();
		formData.append("_method", "PUT");
		formData.append("name", data?.name);
		formData.append("description", editorValue);
		formData.append("price", data?.price);
		// if (data?.file?.length > 0) {
		// 	formData.append("file", data?.file[0]);
		// }

		updateServiceHandeler(formData);
	};

	const [DataError, setDataError] = useState({
		name: "",
		description: "",
		price: "",
		file: "",
	});

	const resetData = () => {
		setDataError({
			name: "",
			description: "",
			price: "",
			file: "",
		});
	};

	const closeVideoModal = () => {
		setUrl("");
	};
	const videoModal = () => {
		return (
			<>
				<div
					onClick={closeVideoModal}
					className={`h-full fixed back_drop bottom-0 left-0  w-full bg-slate-900  z-50 ${styles.back_drop}`}></div>
				<div
					className='w-[400px] h-[300px] z-50 max-w-[90%]'
					style={{
						position: "fixed",
						left: "50%",
						top: "50%",
						transform: "translate(-50%, -50%)",
					}}>
					<CloseIcon
						onClick={closeVideoModal}
						className='cursor-pointer text-[#ffffff]'
					/>
					<video src={url} controls />
				</div>
			</>
		);
	};

	return (
		<>
			{url !== "" && videoModal()}
			<Helmet>
				<title>لوحة تحكم أطلبها | تعديل خدمة</title>
			</Helmet>
			<BackDrop
				onClick={() => {
					handleClosePage();
				}}
			/>
			<div
				className={`fixed bottom-0 left-0 bg-[#F6F6F6] z-30 ${styles.container}`}
				style={{
					width: "1104px",
					maxWidth: "100%",
					height: "calc(100% - 4rem)",
				}}>
				<form onSubmit={handleSubmit(handleAddService)} className='h-full'>
					<div className='flex flex-col justify-between h-full'>
						<div
							className='md:px-8 px-4 pt-24 md:pt-[40px]  pb-[20px]'
							style={{
								backgroundColor: "rgba(235, 235, 235, 1)",
							}}>
							<h2 className='font-semibold md:text-2xl text-[20px] '>
								تعديل خدمة ({product?.name})
							</h2>
						</div>
						{loading ? (
							<div className='flex flex-col items-center justify-center w-full'>
								<CircularLoading />
							</div>
						) : (
							<div
								className={`overflow-y-scroll md:py-12 md:pr-8 p-4 ${styles.content}`}>
								<div className='flex flex-col mb-8 md:flex-row'>
									<h2 className={formTitleClasses}>
										اسم الخدمة<span className='text-red-500'>*</span>
									</h2>
									<div className='flex flex-col'>
										<input
											className={formInputClasses}
											style={formInputStyle}
											placeholder='ادخل اسم الخدمة'
											type='text'
											name='name'
											{...register("name", {
												required: "حقل الاسم مطلوب",
												pattern: {
													value: /^[^-\s][\u0600-\u06FF-A-Za-z ]+$/i,
													message: "يجب ان يتكون الاسم من أحرف",
												},
											})}
										/>
										<span className='text-red-500 text-[16px]'>
											{DataError?.name}
											{errors?.name && errors.name.message}
										</span>
									</div>
								</div>
								<div className='flex flex-col mb-8 md:flex-row '>
									<h2 className={formTitleClasses}>
										تفاصيل الخدمة<span className='text-red-500'>*</span>
									</h2>
									<div className='flex flex-col'>
										<div className='md:w-[555px] w-full outline-0 rounded-md'>
											<TextEditor
												ToolBar={"createOrEditPages"}
												placeholder={"ادخل تفاصيل الخدمة ..."}
											/>
										</div>
										<span className='text-red-500 text-[16px]'>
											{DataError?.description}
											{errors?.description && errors.description.message}
										</span>
									</div>
								</div>
								{/*	<div className='flex flex-col mb-8 md:flex-row'>
									<div className='flex flex-col'>
										<h2 className={formTitleClasses}>اضافة صورة أو فيديو</h2>
										<span className='text-gray-400 text-[14px] font-normal'>
											(الحد الأقصى للصورة 1MB)
										</span>
									</div>
									<div className='flex flex-col gap-2'>
										<div
											className={
												"md:w-[555px] w-full md:h-14 h-[45px] fcc p-3 gap-4 cursor-pointer rounded"
											}
											style={formInputStyle}>
											<div className='flex flex-col'>
												<ImageUploading
													value={images}
													onChange={onChange}
													maxNumber={2}
													dataURLKey='data_url'
													acceptType={[
														"jpg",
														"png",
														"jpeg",
														"svg",
														"gif",
														"mp4",
														"avi",
														"mov",
														"mkv",
													]}
													allowNonImageType={true}>
													{({ imageList, onImageUpload, dragProps }) => (
														// write your building UI

														<div
															className='flex justify-center w-full align-center'
															onClick={() => {
																onImageUpload();
															}}
															{...dragProps}>
															<Box
																sx={{
																	"& circle": { fill: "rgba(36, 36, 36, 1)" },
																}}>
																<AddIcon width={"1.5rem"}></AddIcon>
															</Box>
														</div>
													)}
												</ImageUploading>
											</div>
										</div>
										{images[0] ? (
											<>
												{images[0]?.data_url?.includes(
													"video/mp4" ||
														"video/avi" ||
														"video/mov" ||
														"video/mkv"
												) ? (
													<div className='relative w-[120px] h-[120px]'>
														<div className='relative h-full overflow-hidden'>
															<video
																className='w-full h-full'
																src={images[0]?.data_url}
																alt={images[0]?.data_url}
															/>
															<BsPlayCircle
																onClick={(e) => {
																	setUrl(images[0]?.data_url);
																	e.stopPropagation();
																}}
																className='absolute cursor-pointer top-[50%] left-[50%]'
																style={{
																	transform: "translate(-50%, -50%)",
																}}
																color='#ffffff'
															/>
														</div>
													</div>
												) : (
													<div className='overflow-hidden w-[120px] h-[120px]'>
														<img
															width={"100%"}
															height={"100%"}
															src={images[0]?.data_url}
															alt={images[0]?.data_url}
															loading='lazy'
														/>
													</div>
												)}
											</>
										) : (
											<>
												{imagesPerview?.includes(
													"video/mp4" ||
														"video/avi" ||
														"video/mov" ||
														"video/mkv"
												) ? (
													<div className='relative w-[120px] h-[120px]'>
														<div className='relative h-full overflow-hidden'>
															<video
																className='w-full h-full'
																src={imagesPerview}
																alt={imagesPerview}
															/>
															<BsPlayCircle
																onClick={(e) => {
																	setUrl(imagesPerview);
																	e.stopPropagation();
																}}
																className='absolute cursor-pointer top-[50%] left-[50%]'
																style={{
																	transform: "translate(-50%, -50%)",
																}}
																color='#ffffff'
															/>
														</div>
													</div>
												) : (
													<div className='overflow-hidden w-[120px] h-[120px]'>
														<img
															width={"100%"}
															height={"100%"}
															src={imagesPerview}
															alt={imagesPerview}
															loading='lazy'
														/>
													</div>
												)}
											</>
										)}
										{DataError?.file && (
											<span className='text-red-500 text-[16px]'>
												{DataError?.file}
											</span>
										)}
									</div>
								</div>*/}
								<div className='flex flex-col mb-8 md:flex-row '>
									<h2 className={formTitleClasses}>
										سعر الخدمة<span className='text-red-500'>*</span>
									</h2>
									<div className=''>
										<label
											className='md:w-[555px] w-full md:h-14 h-[45px] flex rounded-md overflow-hidden'
											style={formInputStyle}>
											<div className='flex flex-1 p-3 p-md-4'>
												<Controller
													name={"price"}
													control={control}
													rules={{
														required: "حقل سعر الخدمة مطلوب",
														pattern: {
															value: /^[0-9.]+$/i,
															message: "حقل المبلغ الشهري يجب ان يكون رقم",
														},
														min: {
															value: 1,
															message:
																"حقل المبلغ الشهري يجب ان يكون أكبر من 0",
														},
													}}
													render={({ field: { onChange, value } }) => (
														<input
															className='flex-1 border-none outline-none bg-[#FFFFFF]'
															style={{ direction: "ltr", textAlign: "end" }}
															placeholder='ادخل سعر الخدمة'
															type='text'
															name='price'
															value={value}
															onChange={(e) => {
																onChange(
																	e.target.value.replace(
																		/[^\d.]|\.(?=.*\.)/g,
																		""
																	)
																);
															}}
														/>
													)}
												/>
											</div>
											<div
												className='flex items-center justify-center w-16 h-full text-lg'
												style={{
													borderRight: "1px solid #A7A7A747",
													backgroundColor: "#E9E9E9",
												}}>
												ر.س
											</div>
										</label>
										<span className='text-red-500 text-[16px]'>
											{DataError?.price}
											{errors?.price && errors.price.message}
										</span>
									</div>
								</div>
							</div>
						)}
						<div
							className='flex items-center justify-center gap-4 p-4 md:p-8'
							style={{
								backgroundColor: "rgba(235, 235, 235, 1)",
							}}>
							<Button
								btnType={"submit"}
								className={"md:h-14 h-[45px] md:w-1/3 w-full text-xl"}
								style={{ backgroundColor: `rgba(2, 70, 106, 1)` }}
								type={"normal"}>
								حفظ التعديلات
							</Button>
							<Button
								style={{
									borderColor: `rgba(2, 70, 106, 1)`,
								}}
								textStyle={{ color: "rgba(2, 70, 106, 1)" }}
								className={"md:h-14 h-[45px] md:w-1/3 w-full text-xl"}
								type={"outline"}
								onClick={() => {
									handleClosePage();
								}}>
								إلغاء
							</Button>
						</div>
					</div>
				</form>
			</div>
		</>
	);
};

export default EditService;
