import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../../API/axiosBaseQuery";

// Create API slice
export const subscriptionsApi = createApi({
	reducerPath: "subscriptionsApi",
	baseQuery: axiosBaseQuery({
		baseUrl: "https://backend.atlbha.com/api/Admin/",
	}),
	tagTypes: ["Subscriptions", "PackagesFeatures", "BusinessTrips"],

	endpoints: (builder) => ({
		// Get subscriptions
		getSubscriptions: builder.query({
			query: (args) => ({
				url: `subscriptions?page=${args.page}&number=${args.number}`,
			}),

			transformResponse: (response) => response.data,
			providesTags: ["Subscriptions"],
		}),

		// Get Package
		getPackage: builder.query({
			query: (args) => ({
				url: `package?page=${args.page}&number=${args.number}`,
			}),

			transformResponse: (response) => response.data,
			providesTags: ["Packages"],
		}),

		// delete package
		packageDeleteItem: builder.mutation({
			query: ({ id }) => ({
				url: `package/${id}`,
				method: "DELETE",
			}),
			invalidatesTags: ["Subscriptions"],
		}),

		//Add New Package
		addPackage: builder.mutation({
			query: ({ body }) => {
				return {
					url: `package`,
					method: "POST",
					data: body,
				};
			},

			invalidatesTags: ["Packages"],
		}),

		// show package
		showPackage: builder.query({
			query: ({ id }) => ({
				url: `package/${id}`,
			}),

			transformResponse: (response) => response.data?.packages,
			providesTags: (result, error, id) => [{ type: "Packages", id }],
		}),

		// edit Package
		editPackage: builder.mutation({
			query: ({ body, id }) => {
				return {
					url: `package/${id}`,

					method: "POST",
					data: body,
				};
			},

			invalidatesTags: ["Packages"],
		}),

		changePackageStatus: builder.mutation({
			query: ({ id }) => ({
				url: `changePackageStatus/${id}`,
				method: "GET",
			}),
			invalidatesTags: ["Packages"],
		}),

		// search in Subscriptions
		searchInSubscriptions: builder.mutation({
			query: (arg) => ({
				url: `searchSubscriptionsName?query=${arg.query}`,
				method: "GET",
			}),
		}),

		// add To alert
		addToAlert: builder.mutation({
			query: (data) => {
				return {
					url: `addAlert`,
					method: "POST",
					data: data,
				};
			},
		}),

		// updateSubscriptionsAll
		updateSubscriptionsAll: builder.mutation({
			query: ({ body }) => ({
				url: "updateSubscriptionsAll",
				method: "POST",
				data: body,
			}),
			invalidatesTags: ["Subscriptions"],
		}),

		// change status All
		subscriptionsChangeStatusAll: builder.mutation({
			query: ({ queryParams }) => ({
				url: `subscriptionschangeSatusall?${queryParams}`,
				method: "GET",
			}),
			invalidatesTags: ["Subscriptions"],
		}),

		// delete all subscriptions
		subscriptionsDeleteAll: builder.mutation({
			query: ({ queryParams }) => ({
				url: `subscriptionsdeleteall?${queryParams}`,
				method: "GET",
			}),
			invalidatesTags: ["Subscriptions"],
		}),

		// delete one subscription item
		subscriptionsDeleteItem: builder.mutation({
			query: ({ id }) => ({
				url: `subscriptionsdeleteall?id[]=${id}`,
				method: "GET",
			}),
			invalidatesTags: ["PackagesFeatures"],
		}),

		// Get Packages features
		getPackageFeatures: builder.query({
			query: (args) => ({
				url: `plan?page=${args.page}&number=${args.number}`,
			}),

			transformResponse: (response) => response.data,
			providesTags: ["PackagesFeatures"],
		}),

		// Add Package features
		addPackageFeatures: builder.mutation({
			query: ({ body }) => {
				return {
					url: `plan`,
					method: "POST",
					data: body,
				};
			},

			invalidatesTags: ["PackagesFeatures"],
		}),

		// show Packages features
		showPackageFeatures: builder.query({
			query: ({ id }) => ({
				url: `plan/${id}`,
			}),

			transformResponse: (response) => response.data?.plans,
			providesTags: (result, error, id) => [{ type: "PackagesFeatures", id }],
		}),

		// edit Packages features
		editPackageFeatures: builder.mutation({
			query: ({ body, id }) => {
				return {
					url: `plan/${id}`,
					method: "POST",
					data: body,
				};
			},

			invalidatesTags: ["PackagesFeatures"],
		}),

		// delete All Packages features
		planDeleteAll: builder.mutation({
			query: ({ queryParams }) => ({
				url: `plansDeleteAll?${queryParams}`,

				method: "GET",
			}),
			invalidatesTags: ["PackagesFeatures"],
		}),

		// delete one Packages features item
		planDeleteItem: builder.mutation({
			query: ({ id }) => ({
				url: `plan/${id}`,
				method: "DELETE",
			}),
			invalidatesTags: ["PackagesFeatures"],
		}),

		// search in Packages features
		searchInPackagesFeatures: builder.mutation({
			query: (arg) => ({
				url: `searchPlanName?query=${arg.query}`,
				method: "GET",
			}),
		}),

		// Get Packages features
		getBusinessTrips: builder.query({
			query: (args) => ({
				url: `trip?page=${args.page}&number=${args.number}`,
			}),

			transformResponse: (response) => response.data,
			providesTags: ["BusinessTrips"],
		}),

		// Add Package features
		addBusinessTrip: builder.mutation({
			query: ({ body }) => {
				return {
					url: `trip`,
					method: "POST",
					data: body,
				};
			},

			invalidatesTags: ["BusinessTrips"],
		}),

		// show Packages features
		showBusinessTrip: builder.query({
			query: ({ id }) => ({
				url: `trip/${id}`,
			}),

			transformResponse: (response) => response.data?.trip_details,
			providesTags: (result, error, id) => [{ type: "BusinessTrips", id }],
		}),

		// edit Packages features
		editBusinessTrip: builder.mutation({
			query: ({ body, id }) => {
				return {
					url: `trip/${id}`,
					method: "POST",
					data: body,
				};
			},

			invalidatesTags: ["BusinessTrips"],
		}),

		// delete one Packages features item
		businessTripsDeleteItem: builder.mutation({
			query: ({ id }) => ({
				url: `trip/${id}`,
				method: "DELETE",
			}),
			invalidatesTags: ["BusinessTrips"],
		}),
	}),
});

// Export endpoints and hooks
export const {
	useGetPackageQuery,
	usePackageDeleteItemMutation,
	useGetSubscriptionsQuery,
	useAddPackageMutation,
	useUpdateSubscriptionsAllMutation,
	useChangePackageStatusMutation,
	useSearchInSubscriptionsMutation,
	useAddToAlertMutation,
	useSubscriptionsChangeStatusAllMutation,
	useSubscriptionsDeleteAllMutation,
	useSubscriptionsDeleteItemMutation,
	useShowPackageQuery,
	useEditPackageMutation,
	useGetPackageFeaturesQuery,
	useAddPackageFeaturesMutation,
	useShowPackageFeaturesQuery,
	useEditPackageFeaturesMutation,
	usePlanDeleteAllMutation,
	usePlanDeleteItemMutation,
	useSearchInPackagesFeaturesMutation,
	useGetBusinessTripsQuery,
	useAddBusinessTripMutation,
	useShowBusinessTripQuery,
	useEditBusinessTripMutation,
	useBusinessTripsDeleteItemMutation,
} = subscriptionsApi;
